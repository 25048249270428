.configMain{
    background-color: rgb(245 249 252);
}

.configSection{
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 12px;
    border-bottom: 10px solid royalblue;
}

/* .table-responsive td, .table-responsive th {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-align: center;
}

.table {
    table-layout: fixed;
    over-flow: break-word;
} */