*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

body{
  /* font-family: "Inter", sans-serif; */
  height: 100vh;

}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }

  .refreshbtn {
    text-decoration: underline;
    color: blue;

  }

  .refreshbtn:hover{
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .featureBtn{
    /* background-color: red; */
    height: 40px;
  }

  .react-tel-input .form-control{
    height: 40px !important;
  }

/* body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.btnPrimary {
  background-color: #4B0082;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.btnUpdate {
  background-color: #FFA726;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.btnDelete {
  background-color: #E53935;
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.btnEdit {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
  padding: 5px;
}


.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.navbar {
  background-color: #212529; /* Same as primary button color */
  color: white;
  padding: 10px;
}

.sidebar {
  background-color: #f4f4f4;
  width: 70px;
  padding: 10px;
}

.content {
  flex: 1;
  display: flex;
}

.main-content {
  flex: 1;
  padding: 20px;
  width: 80vw;
}

.table-cls{
 overflow-x: auto;
}

.table-Dashboard{
  table-layout: fixed;
  word-wrap: break-word;
}

