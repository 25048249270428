.own-flex{
    display: flex;
}

@media only screen and (max-width: 480px) {
    .own-flex {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }