/* styles.css */
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* background-color: #6366f1; Same as primary button color */
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .fullscreen-iframe {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100vw;
    height: 95vh;
    border: none;
    z-index: 999;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hidden {
    display: none;
  }
  
  .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #E53935; /* Red color for close */
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  