/* Sidebar.css */
/* .sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70px;
  height: calc(100vh - 40px);
  position: fixed;
  left: 0;
  top: 0;
  background-color: #f0f4f9;
  color: black;
  align-items: center;
  padding-top: 20px;
  z-index: 2;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
} */

.sidebar.open {
  transform: translateX(0); /* Show sidebar when open */
}

.sidebar-links {
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-link {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  color: #3c4043;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-link:hover {
  color: blue;
  font-weight: bold;
}

.sidebar-link i {
  font-size: 20px; 
  display: block;
}

.sidebar-link .link-text {
  font-size: 12px; 
  display: block;
}

.icon {
  width: 1.5rem; 
  height: 1.5rem;
  margin-bottom: 0.5rem;
}

.icon:hover {
  color: blue;
}

.hamburger {
  position: fixed;
  bottom: 20px; /* Position at the bottom of the screen */
  right: 20px; /* Position on the right side of the screen */
  background-color: #f0f4f9;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
}

.hamburger-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 768px) {
  .sidebar {
      transform: translateX(0); /* Always visible on larger screens */
  }

  .hamburger {
      display: none; /* Hide hamburger on larger screens */
  }
}
